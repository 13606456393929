<template>
  <div>
    <vx-card class="mb-base">
      <vx-table-filter-sorter :type="this.type"/>
    </vx-card>
    <vx-card v-if="$acl.check('manager')" title="Upload de Arquivos" class="mb-base">
      <vx-upload-file :type="type"/>
    </vx-card>
  </div>
</template>
<script>
  import vxTableFilterSorter from '@/views/Files/components/TableFilterSorter.vue';
  import vxUploadFile from '@/views/Files/components/UploadFile.vue'

  export default {
    components: {
      vxTableFilterSorter,
      vxUploadFile
    },
    data() {
      return {
        type: 'thematic_maps',
        items: []
      }
    },
    methods: {
      submitData() {
        this.$validator.validateAll()
      }
    },

  }
</script>

